/**
 * Emoticons mapping by set id.
 */
export default {
  0: {
    'B-?\\)': 7,
    'R-?\\)': 14,
    '[oO](_|\\.)[oO]': 6,
    '\\&gt\\;\\(': 4,
    '\\&lt\\;3': 9,
    '\\:-?(o|O)': 8,
    '\\:-?(p|P)': 12,
    '\\:-?D': 3,
    '\\:-?[\\\\/]': 10,
    '\\:-?[z|Z|\\|]': 5,
    '\\:-?\\(': 2,
    '\\:-?\\)': 1,
    '\\;-?(p|P)': 13,
    '\\;-?\\)': 11,
  },
  33: {
    'B-?\\)': 441,
    'R-?\\)': 435,
    '[oO](_|\\.)[oO]': 437,
    '\\&gt\\;\\(': 432,
    '\\&lt\\;3': 445,
    '\\:-?(o|O)': 436,
    '\\:-?(p|P)': 438,
    '\\:-?D': 443,
    '\\:-?[\\\\/]': 433,
    '\\:-?[z|Z|\\|]': 444,
    '\\:-?\\(': 434,
    '\\:-?\\)': 440,
    '\\;-?(p|P)': 442,
    '\\;-?\\)': 439,
  },
  42: {
    'B-?\\)': 500,
    'R-?\\)': 484,
    '[oO](_|\\.)[oO]': 497,
    '\\&gt\\;\\(': 498,
    '\\&lt\\;3': 483,
    '\\:-?(o|O)': 492,
    '\\:-?(p|P)': 490,
    '\\:-?D': 496,
    '\\:-?[\\\\/]': 493,
    '\\:-?[z|Z|\\|]': 494,
    '\\:-?\\(': 489,
    '\\:-?\\)': 499,
    '\\;-?(p|P)': 491,
    '\\;-?\\)': 501,
  },
} as Record<number, Record<string, number>>
